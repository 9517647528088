import { useEffect } from 'react'
import { useHotelId } from './use-hotel-id'

export const useChatScript = () => {
  const { getHotelId } = useHotelId()

  const insertChatScript = () => {
    if (document.getElementById('chat-script-block')?.children.length) {
      return
    }
    const chatScript = document.createElement('script')
    chatScript.src = 'https://app.webchat.obotai.com/loader/?cid=QTZsqAd6gL'
    chatScript.defer = true
    document.getElementById('chat-script-block')?.appendChild(chatScript)
  }

  useEffect(() => {
    const hotelId = getHotelId()
    if (!hotelId) {
      return
    }
    if (!['1', '7ae76003-8bad-48fb-91a2-a932b113f101'].includes(hotelId)) {
      return
    }
    insertChatScript()
  }, [])
}
